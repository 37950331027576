<template>
    <div v-if="isLoading">
        <LoaderIndicator />
    </div>

    <div class="modal" id="loginModal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable" role="document">
         <div class="modal-content">
             <div class="modal-header">
               <h5 class="modal-title">Login</h5>
             </div>
             <div class="modal-body">
                 <form>
                  <div class="form-group">
                    <label for="email">Email</label>
                    <input type="email" class="form-control" id="email" v-model="login_email" placeholder="Email" required>
                  </div>

                  <div class="form-group">
                    <label for="password">Parola</label>
                    <input type="password" class="form-control" id="password" v-model="login_password" placeholder="Parola" required>
                  </div>
                </form>
             </div>
             <div class="modal-footer d-flex justify-content-center">
               <button type="button" @click="login" class="btn btn-primary">Login</button>
             </div>
         </div>
        </div>
    </div>

    <div class="modal" id="menuModal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
         <div class="modal-content">
             <div class="modal-header">
               <h5 class="modal-title">Meniu</h5>

               <button type="button" data-dismiss="modal" class="close">
                 <span aria-hidden="true">&times;</span>
               </button>
             </div>
             <div v-html="current_html" class="modal-body"></div>
             <div class="modal-footer">
               <button type="button" data-dismiss="modal" class="btn btn-secondary">Renunta</button>

               <button type="button" @click="copyTable" class="btn btn-primary">Copiaza meniu</button>
             </div>
         </div>
        </div>
    </div>

    <div class="modal" id="adaugaFoodModal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
         <div class="modal-content">
           <div class="modal-header">
             <h5 v-if="!isEditMode" class="modal-title">Adauga Produs</h5>
             <h5 v-else class="modal-title">Modifica Produs</h5>

             <button type="button" data-dismiss="modal" class="close">
               <span aria-hidden="true">&times;</span>
             </button>
           </div>
           <div class="modal-body">
               <form>
                 <div class="form-group">
                   <label for="foodName">Denumire produs</label>
                   <input type="text" v-model="currentFood.name" class="form-control" id="foodName" required>
                 </div>
                 <div class="form-row">
                   <div class="form-group col">
                       <label for="categorie">Categorie</label>
                       <select v-model="currentFood.category" class="form-control" id="category" required>
                         <option value="" disabled>- categorie -</option>
                         <option v-for="category in categories" :key="category" :value="category">{{ category }}</option>
                       </select>
                   </div>
                   <div class="form-group col">
                       <label for="calories">Calorii</label>
                       <input type="text" v-model="currentFood.cal" class="form-control" id="calories" required pattern="^[0-9]*\.?[0-9]+$">
                   </div>
                   <div class="form-group col">
                       <label for="protein">Proteine</label>
                       <input type="text" v-model="currentFood.protein" class="form-control" id="protein" required pattern="^[0-9]*\.?[0-9]+$">
                   </div>
                   <div class="form-group col">
                       <label for="carbs">Carbohidrati</label>
                       <input type="text" v-model="currentFood.sugars" class="form-control" id="carbs" required pattern="^[0-9]*\.?[0-9]+$">
                   </div>
                   <div class="form-group col">
                       <label for="fats">Lipide</label>
                       <input type="text" v-model="currentFood.fats" class="form-control" id="fats" required pattern="^[0-9]*\.?[0-9]+$">
                   </div>
                   <div class="form-group col">
                       <label for="fibers">Fibre</label>
                       <input type="text" v-model="currentFood.fiber" class="form-control" id="fibers" required pattern="^[0-9]*\.?[0-9]+$">
                   </div>
                </div>
               </form>
           </div>
           <div class="modal-footer">
             <button type="button" data-dismiss="modal" class="btn btn-secondary">Renunta</button>

             <button v-if="!isEditMode" @click="addFood" type="button" data-dismiss="modal" :class="[validForm ? '':'disabled', 'btn', 'btn-primary']">Adauga</button>
             <button v-else type="button" @click="addFood" data-dismiss="modal" class="btn btn-primary">Modifica</button>
           </div>
         </div>
        </div>
    </div>

    <div class="modal" id="adaugaMenuModal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
         <div class="modal-content">
           <div class="modal-header">
             <h5 v-if="!isEditModeMenu" class="modal-title">Adauga Meniu</h5>
             <h5 v-else class="modal-title">Modifica Meniu</h5>

             <button type="button" data-dismiss="modal" class="close">
               <span aria-hidden="true">&times;</span>
             </button>
           </div>
           <div class="modal-body">
               <form>
                   <div class="form-group">
                       <label for="menuName"><b>Nume Meniu</b></label>
                       <input type="text" v-model="currentMenu.name" class="form-control" id="menuName" required>
                   </div>
               </form>
           </div>
           <div class="modal-footer">
             <button type="button" data-dismiss="modal" class="btn btn-secondary">Renunta</button>

             <button v-if="!isEditModeMenu" type="button" data-dismiss="modal" @click="addMenu" :class="[validFormMenu ? '':'disabled', 'btn', 'btn-primary']">Adauga</button>
             <button v-else type="button" data-dismiss="modal" @click="addMenu" class="btn btn-primary">Modifica</button>
           </div>
         </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 table-responsive">
                <table class="table table-condensed table-bordered table-striped mt-0 shadow">
                  <thead class="thead-dark sticky-top">
                      <tr>
                        <th colspan="8">
                            <div class="input-group">
                                <input type="text" v-model="searchTerm" @input="inputChange($event.target.value)" class="form-control" id="searchInput" :placeholder="'Cauta produs (' + foods.length + ' produse in baza de date)...'">
                                <div class="input-group-append">
                                    <button class="btn btn-secondary" type="button" @click="searchTerm=''">&times;</button>
                                    <button class="btn btn-primary ml-1" type="button" @click="openAddProductModal(true)">+ Creaza Produs</button>
                                </div>
                            </div>
                        </th>
                      </tr>
                    <tr>
                      <th style="width:400px" class="text-left">Denumire</th>
                      <th>Categ</th>
                      <th>KCal</th>
                      <th>P</th>
                      <th>L</th>
                      <th>G</th>
                      <th>F</th>
                      <th>Actiuni</th>
                    </tr>
                  </thead>
                  <tbody v-if="filteredFoods.length > 0" >
                      <tr v-for="(food, index) in paginateFoods(filteredFoods)" :key="index">
                        <td tyle="width:400px" class="text-left"><b>{{ food.name }}</b></td>
                        <td>{{ food.category }}</td>
                        <td>{{ food.cal }}</td>
                        <td>{{ food.protein }}</td>
                        <td>{{ food.fats }}</td>
                        <td>{{ food.sugars }}</td>
                        <td>{{ food.fiber }}</td>
                        <td>
                          <div class="btn-group">
                              <button @click="editFood(food)" class="btn btn-primary mr-1 ml-1"><i class="fa fa-pencil"></i></button>
                              <button @click="deleteFood(food)" class="btn btn-danger mr-1 ml-1"><i class="fa fa-trash"></i></button>
                              <button @click="addToMenu(food)" :class="[(selectedMenuIndex != -1 ? '':'disabled-fake'),'btn btn-info ml-1 ml-1']"><i class="fa fa-chevron-right"></i></button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                          <td colspan="8" style="padding:5px!important" >
                              <button @click="previousPage" class="btn btn-secondary mr-1 ml-1" :disabled="currentPage === 1"><i class="fa fa-chevron-left"></i> Inapoi</button>
                              <span>Pag. {{ currentPage }} din {{ totalPages }}, {{itemsPerPage}} produse pe pagina</span>
                              <button @click="nextPage" class="btn btn-secondary mr-1 ml-1" :disabled="currentPage === totalPages">Inainte <i class="fa fa-chevron-right"></i></button>
                          </td>
                      </tr>
                  </tbody>
                  <tbody v-else>
                      <tr>
                          <td colspan="8">
                              Nu am gasit produse
                          </td>
                      </tr>
                  </tbody>
                </table>
            </div>

            <div class="col-md-6 table-responsive">
                <table class="table table-bordered table-striped m-0">
                   <thead class="thead-dark">
                       <tr>
                         <th>
                             <div class="input-group">
                                 <input type="text" v-model="searchTermMenu" @input="inputChangeMenu($event.target.value)"  class="form-control" id="searchInputMenu" :placeholder="'Cauta meniu (' + menus.length + ' meniuri create)...'">
                                 <div class="input-group-append">
                                     <button class="btn btn-secondary" type="button" @click="searchTermMenu=''">&times;</button>
                                     <button class="btn btn-primary ml-1" type="button" @click="openAddMenuModal(true)">+ Creaza Meniu</button>
                                 </div>
                             </div>
                         </th>
                       </tr>
                   </thead>
                </table>
                <div v-if="filteredMenus.length > 0" class="shadow mt-0 sticky-top">
                    <table style="background-color:white" v-for="(menu, index) in filteredMenus" :key="index" :class="[selectedMenuIndex == index ? 'selected':'', 'table table-bordered table-striped m-0']">
                        <tbody>
                          <tr :class="[(selectedMenuIndex != index) ? '':'bg-pale']">
                            <td  @click="expandMenu(index)" style="cursor: pointer; width:50px">
                              <button :class="['btn mr-2', (selectedMenuIndex == index) ? 'btn-info' : 'btn-secondary', 'ml-1', 'ml-1']">
                                  <i v-if="selectedMenuIndex != index" class="fa fa-chevron-down"></i>
                                  <i v-else class="fa fa-eye"></i>
                              </button>
                            </td>

                            <td align="left" @click="expandMenu(index)" style="cursor: pointer; width:175px" :class="[(menu.products && menu.products.length > 0 || selectedMenuIndex == index) ? '' : 'disabled-fake', 'text-left menuName']">
                                <b>{{ menu.name }}</b>
                                <br />
                                <small>({{menu.products && menu.products.length ? menu.products.length:0}} produse)</small>
                            </td>

                            <td @click="expandMenu(index)" style="cursor: pointer;">
                                <table class="table mb-0 table-stripped table-condensed" style="width:100; %font-size:12px; background-color:white">
                                    <tr style="background-color:#ccc; color:#000">
                                        <td>Cant (g)</td>
                                        <td>KCal</td>
                                        <td>Prot</td>
                                    </tr>
                                    <tr>
                                        <td><b>{{ menu.totals['grand'].grm.toFixed(2) || 0 }}</b></td>
                                        <td><b>{{ menu.totals['grand'].cal.toFixed(2) || 0 }}</b></td>
                                        <td><b>{{ menu.totals['grand'].protein.toFixed(2) || 0 }}</b></td>
                                    </tr>

                                    <tr style="background-color:#ccc; color:#000">
                                        <td>Lip</td>
                                        <td>Gluc</td>
                                        <td>Fibre</td>
                                    </tr>
                                    <tr>
                                        <td><b>{{ menu.totals['grand'].fats.toFixed(2) || 0 }}</b></td>
                                        <td><b>{{ menu.totals['grand'].sugars.toFixed(2) || 0 }}</b></td>
                                        <td><b>{{ menu.totals['grand'].fiber.toFixed(2) || 0 }}</b></td>
                                    </tr>
                                </table>
                            </td>

                            <td style="width:150px">
                                <div class="btn-group">
                                  <button @click="editMenu(index)" class="btn btn-primary mr-1 ml-1"><i class="fa fa-pencil"></i></button>
                                  <button @click="deleteMenu(index)" class="btn btn-danger mr-1 ml-1"><i class="fa fa-trash"></i></button>
                                  <button @click="exportMenu(index, 'xls')" class="btn btn-info mr-1 ml-1"><i class="fa fa-file-excel-o"></i></button>
                                  <button @click="exportMenu(index, 'html')" class="btn btn-warning mr-1 ml-1"><i class="fa fa-file-o"></i></button>
                                </div>
                            </td>
                          </tr>

                          <tr v-if="selectedMenuIndex == index">
                              <td v-if="menu.products && menu.products.length > 0" colspan="4">
                                    <div style="max-height:80vh; overflow:scroll">
                                      <table style="font-size:12px" class="table table-bordered table-striped">
                                        <tbody style="border-bottom:5px solid #ccc">
                                            <tr>
                                                <th colspan="9" class="text-left">
                                                    <i>Fara perioada din zi aleasa (nealocate)</i>
                                                </th>
                                            </tr>
                                            <tr v-if="filterMenuByPerioda(menu.products, 'select').length!=0">
                                                <th style="width:200px" class="text-left">Produs</th>
                                                <th style="width:150px">Cant (g)</th>
                                                <th>KCal</th>
                                                <th>Prot</th>
                                                <th>Lip</th>
                                                <th>Gluc</th>
                                                <th>Fibre</th>
                                                <th style="width:150px">Perioada</th>
                                                <th>Actiuni</th>
                                            </tr>
                                            <tr v-for="(foodProd, indexFoodProd) in filterMenuByPerioda(menu.products, 'select')" :key="indexFoodProd">
                                              <td class="text-left"><b>{{ foodProd.name }}</b></td>
                                              <td><input style="font-size:12px" type="numeric" min="0" max="9999" step="0.01" @blur="inputChangeMenuFoodBlur($event.target)" @focus="$event.target.select()" @input="inputChangeMenuFood(foodProd, $event.target.value)" v-model="foodProd.grm" class="form-control text-center" id="foodGrm" required pattern="^[0-9]*\.?[0-9]+$"></td>
                                              <td>{{ foodProd.cal }}</td>
                                              <td>{{ foodProd.protein }}</td>
                                              <td>{{ foodProd.fats }}</td>
                                              <td>{{ foodProd.sugars }}</td>
                                              <td>{{ foodProd.fiber }}</td>
                                              <td>
                                                  <select @change="updateFoodProd" style="font-size:12px" v-model="foodProd.perioada" class="form-control text-center" id="foodPerioada" required>
                                                      <option value="select" disabled>- perioada din zi -</option>
                                                      <option v-for="perioada in perioade" :key="perioada" :value="perioada">{{ perioada.replaceAll("_", " ") }}</option>
                                                  </select>
                                              </td>
                                              <td>
                                                <button @click="deleteMenuFood(foodProd)" class="btn btn-danger mr-1 ml-1"><i class="fa fa-trash"></i></button>
                                              </td>
                                            </tr>
                                            <tr v-if="filterMenuByPerioda(menu.products, 'select').length!=0">
                                              <td class="text-left"><b>SUBTOTAL</b></td>
                                              <td><b>{{ menu.totals['select'].grm.toFixed(2) || 0 }}</b></td>
                                              <td><b>{{ menu.totals['select'].cal.toFixed(2) || 0 }}</b></td>
                                              <td><b>{{ menu.totals['select'].protein.toFixed(2) || 0 }}</b></td>
                                              <td><b>{{ menu.totals['select'].fats.toFixed(2) || 0 }}</b></td>
                                              <td><b>{{ menu.totals['select'].sugars.toFixed(2) || 0 }}</b></td>
                                              <td><b>{{ menu.totals['select'].fiber.toFixed(2) || 0 }}</b></td>
                                              <td>
                                              </td>
                                              <td>
                                              </td>
                                            </tr>
                                            <tr v-if="filterMenuByPerioda(menu.products, 'select').length==0">
                                                <th colspan="9">
                                                    <i style="fon-size:10px; font-weight:normal; color:#333">Nu exista produse adaugate nealocate</i>
                                                </th>
                                            </tr>
                                        </tbody>

                                        <tbody v-for="perioada_meniu in perioade" :key="perioada_meniu" style="border-bottom:3px solid #ccc">
                                            <tr>
                                                <th colspan="1" class="text-left">
                                                    <b style="font-size:16px">{{perioada_meniu.replaceAll("_", " ")}}</b>
                                                </th>
                                                <th colspan="11" class="text-left">
                                                    <input type="text" :placeholder="'Descriere ' + perioada_meniu.replaceAll('_',' ')" v-model="menu.texts[perioada_meniu]" @input="inputChangeMenuText()" class="form-control">
                                                </th>
                                            </tr>

                                            <tr v-if="filterMenuByPerioda(menu.products, perioada_meniu).length!=0">
                                                <th style="width:200px" class="text-left">Produs</th>
                                                <th style="width:150px">Cant (g)</th>
                                                <th>KCal</th>
                                                <th>P</th>
                                                <th>L</th>
                                                <th>G</th>
                                                <th>F</th>
                                                <th style="width:150px">Perioada</th>
                                                <th>Actiuni</th>
                                            </tr>

                                            <tr v-if="filterMenuByPerioda(menu.products, perioada_meniu).length==0">
                                                <th colspan="9">
                                                    <i style="fon-size:10px; font-weight:normal; color:#333">Nu exista produse adaugate pentru {{ perioada_meniu.replaceAll("_"," ") }}</i>
                                                </th>
                                            </tr>
                                            <tr v-for="(foodProd, indexFoodProd) in filterMenuByPerioda(menu.products, perioada_meniu)" :key="indexFoodProd">
                                              <td class="text-left"><b>{{ foodProd.name }}</b></td>
                                              <td><input style="font-size:12px" @focus="$event.target.select()" type="numeric" min="0" max="9999" step="0.01" @blur="inputChangeMenuFoodBlur($event.target)" @input="inputChangeMenuFood(foodProd, $event.target.value)" v-model="foodProd.grm" class="form-control text-center" id="foodGrm" required pattern="^[0-9]*\.?[0-9]+$"></td>
                                              <td>{{ foodProd.cal }}</td>
                                              <td>{{ foodProd.protein }}</td>
                                              <td>{{ foodProd.fats }}</td>
                                              <td>{{ foodProd.sugars }}</td>
                                              <td>{{ foodProd.fiber }}</td>
                                              <td>
                                                  <select @change="updateFoodProd" style="font-size:12px" v-model="foodProd.perioada" class="form-control text-center" id="foodPerioada" required>
                                                      <option value="" disabled>- perioada -</option>
                                                      <option v-for="perioada in perioade" :key="perioada" :value="perioada">{{ perioada.replaceAll("_", " ") }}</option>
                                                  </select>
                                              </td>
                                              <td>
                                                <button @click="deleteMenuFood(foodProd)" class="btn btn-danger mr-1 ml-1"><i class="fa fa-trash"></i></button>
                                              </td>
                                            </tr>
                                            <tr v-if="filterMenuByPerioda(menu.products, perioada_meniu).length!=0">
                                              <td class="text-left"><b>SUBTOTAL</b></td>
                                              <td><b>{{ menu.totals[perioada_meniu].grm.toFixed(2) || 0 }}</b></td>
                                              <td><b>{{ menu.totals[perioada_meniu].cal.toFixed(2) || 0 }}</b></td>
                                              <td><b>{{ menu.totals[perioada_meniu].protein.toFixed(2) || 0 }}</b></td>
                                              <td><b>{{ menu.totals[perioada_meniu].fats.toFixed(2) || 0 }}</b></td>
                                              <td><b>{{ menu.totals[perioada_meniu].sugars.toFixed(2) || 0 }}</b></td>
                                              <td><b>{{ menu.totals[perioada_meniu].fiber.toFixed(2) || 0 }}</b></td>
                                              <td>
                                              </td>
                                              <td>
                                              </td>
                                            </tr>
                                        </tbody>
                                        <tbody style="font-size:14px!important">
                                            <tr>
                                              <th style="width:200px" class="text-left"></th>
                                              <th style="width:150px">Cant (g)</th>
                                              <th>KCal</th>
                                              <th>P</th>
                                              <th>L</th>
                                              <th>G</th>
                                              <th>F</th>
                                              <th style="width:150px"></th>
                                              <th></th>
                                            </tr>
                                            <tr style="font-size:14px">
                                              <td class="text-left"><b>TOTAL <small>({{menu.products && menu.products.length ? menu.products.length:0}} produse)</small></b></td>
                                              <td><b>{{ menu.totals['grand'].grm.toFixed(2) || 0 }}</b></td>
                                              <td><b>{{ menu.totals['grand'].cal.toFixed(2) || 0 }}</b></td>
                                              <td><b>{{ menu.totals['grand'].protein.toFixed(2) || 0 }}</b></td>
                                              <td><b>{{ menu.totals['grand'].fats.toFixed(2) || 0 }}</b></td>
                                              <td><b>{{ menu.totals['grand'].sugars.toFixed(2) || 0 }}</b></td>
                                              <td><b>{{ menu.totals['grand'].fiber.toFixed(2) || 0 }}</b></td>
                                              <td>
                                              </td>
                                              <td>
                                              </td>
                                            </tr>
                                        </tbody>
                                      </table>
                                  </div>
                              </td>

                              <td v-else colspan="4">
                                  Nu exista inca produse adaugate in acest meniu
                              </td>
                          </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else  class="shadow mt-0 sticky-top">
                   <p class="p-5">
                       Nu am gasit meniuri
                   </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase, ref as storageRef, set, onValue} from "firebase/database";
import { useToast } from "vue-toastification";
import axios from 'axios';
import $ from 'jquery';
import CryptoJS from 'crypto-js';
import debounce from 'lodash/debounce';
import LoaderIndicator from './LoaderIndicator.vue';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
//import bootbox from 'bootbox';

const firebaseConfig = {
  apiKey: "AIzaSyD6PqNaGb97cdTKrAzTZVums7YzSEAD2_Y",
  authDomain: "dbfood-809b9.firebaseapp.com",
  databaseURL: "https://dbfood-809b9-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dbfood-809b9",
  storageBucket: "dbfood-809b9.appspot.com",
  messagingSenderId: "555183367239",
  appId: "1:555183367239:web:08c4a1307e588e3544bd62"
};

initializeApp(firebaseConfig);
const auth = getAuth();

export default {
  setup() {
      const toast = useToast();

      return { toast }
  },
  data() {
    return {
      login_email: null,
      login_password: null,
      loggedIn: false,

      itemsPerPage: 25,
      currentPage: 1,

      adaugaFoodModal: null,
      adaugaMenuModal: null,
      menuModal: null,
      loginModal: null,

      selectedMenuIndex: -1,

      searchTerm: '',
      searchTermMenu: '',

      currentMenu: {
        texts: {
            'Mic_dejun': '',
            'Gustare_1': '',
            'Pranz': '',
            'Gustare_2': '',
            'Cina': '',
        },
        products: [],
        name: "",
        id:"",
      },
      currentFood: {
        name: "",
        category: "",
        cal: null,
        protein: null,
        sugars: null,
        fats: null,
        fiber: null,
      },

      current_html: '',

      foods: [],
      filteredFoods: [],

      menus: [],
      filteredMenus: [],

      foodsRef: null,
      menusRef: null,

      isEditMode: false,
      isEditModeMenu: false,

      isLoading: true,

      perioade: [
          'Mic_dejun',
          'Gustare_1',
          'Pranz',
          'Gustare_2',
          'Cina'
      ],

      categories: [
          'Oua',
          'Carne',
          'Branzeturi',
          'Lactate',
          'Cereale',
          'Legume',
          'Peste',
          'Seminte',
          'Fructe',
          'Grasimi',
          'Dulciuri',
          'Fast-Food',
          'Bauturi',
          'Condimente',
          'Sosuri',
          'Snackuri',
          'Mezeluri',
          'Suplimente',
          'Paine',
          'Altele'
      ]
    };
  },
  watch: {
    searchTerm: {
      deep: false,
      handler(newValue) {
        if (newValue == '') {
            this.searchProduct(newValue);
        }
      },
    },
    searchTermMenu: {
      deep: false,
      handler(newValue) {
        if (newValue == '') {
            this.searchMenu(newValue);
        }
      },
    },
  },
  components: {
    LoaderIndicator
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredFoods.length / this.itemsPerPage);
    },
    validForm() {
        return this.currentFood.category != '' &&
               this.currentFood.name != '' &&
               this.currentFood.cal &&
               this.currentFood.protein &&
               this.currentFood.sugars &&
               this.currentFood.fats &&
               this.currentFood.fiber;
    },
    validFormMenu() {
        return this.currentMenu.name != ''
    }
  },
  created() {
    setTimeout(() => {
        this.loginModal = $('#loginModal').modal({
            'backdrop': 'static'
        });
    }, 500);

    const db = getDatabase();
    this.foodsRef = storageRef(db, 'foods');
    this.menusRef = storageRef(db, 'menus');

    this.fetchData();

    /*this.crawl('https://calorii.oneden.com/Calorii-Oua/', 'Oua');
    this.crawl('https://calorii.oneden.com/Calorii-Carne/', 'Carne');
    this.crawl('https://calorii.oneden.com/Calorii-Branzeturi/', 'Branzeturi');
    this.crawl('https://calorii.oneden.com/Calorii-Lactate/', 'Lactate');
    this.crawl('https://calorii.oneden.com/Calorii-Cereale/', 'Cereale');
    this.crawl('https://calorii.oneden.com/Calorii-Legume/', 'Legume');
    this.crawl('https://calorii.oneden.com/Calorii-Peste/', 'Peste');
    this.crawl('https://calorii.oneden.com/Calorii-Seminte/', 'Seminte');
    this.crawl('https://calorii.oneden.com/Calorii-Fructe/', 'Fructe');
    this.crawl('https://calorii.oneden.com/Calorii-Grasimi/', 'Grasimi');
    this.crawl('https://calorii.oneden.com/Calorii-Dulciuri/', 'Dulciuri');
    this.crawl('https://calorii.oneden.com/Calorii-Fast-food/', 'Fast Food');
    this.crawl('https://calorii.oneden.com/Calorii-Bauturi/', 'Bauturi');
    this.crawl('https://calorii.oneden.com/Calorii-Condimente/', 'Condimente');
    this.crawl('https://calorii.oneden.com/Calorii-Sosuri/', 'Sosuri');
    this.crawl('https://calorii.oneden.com/Calorii-Snackuri/', 'Snackuri');
    this.crawl('https://calorii.oneden.com/Calorii-Mezeluri/', 'Mezeluri');
    this.crawl('https://calorii.oneden.com/Calorii-Suplimente/', 'Suplimente');
    this.crawl('https://calorii.oneden.com/Calorii-Paine/', 'Paine');
    this.crawl('https://calorii.oneden.com/Calorii-Altele/', 'Altele');*/

    //setTimeout(() => { this.updateDb(); }, 20 * 1000);
    setTimeout(() => {
        this.doBackup();
    }, 10 * 1000);

    setInterval(() => {
        this.doBackup();
    }, 15 * 60 * 1000);
  },
  methods: {
    crawl(url, category) {
        console.log('Crawl start', url, category);
        axios.get('https://bdorub.duckdns.org/proxy/?url=' + url).then(response => {
            const $html = $(response.data);
            const $content_table = $html.find('#content table.tabelcalorii');

            $content_table.find('tbody tr.color1, tbody tr.color0').each((i_tr, tr) => {
              let row_data = [];

              $(tr).find('td').each((i_td, td) => {
                  row_data.push($(td).text());
              });

              this.foods.push({
                  id: CryptoJS.MD5(row_data[0]).toString(),
                  category: category,
                  name: row_data[0],
                  cal: row_data[1],
                  protein: row_data[2],
                  fats: row_data[3],
                  sugars: row_data[4],
                  fiber: row_data[5]
              });
            });

            console.log(category, this.foods.length);
        });
    },
    login() {
        signInWithEmailAndPassword(auth, this.login_email, this.login_password)
            .then((userCredential) => {
              console.log("User logged in:", userCredential.user);

              this.loggedIn = true;

              this.toast.success('Salut ' + userCredential.user.email + '!', {
                 position: "top-left",
                 timeout: 2500,
                 closeOnClick: true,
                 pauseOnFocusLoss: true,
                 pauseOnHover: true,
                 draggable: true,
                 draggablePercent: 0.6,
                 showCloseButtonOnHover: false,
                 hideProgressBar: true,
                 closeButton: "button",
                 rtl: false
              });

              this.loginModal.modal("hide");
            })
            .catch((error) => {
              //console.error("Login error:", error.message);
             // alert(error.message);
              this.toast.error(error.message, {
                 position: "top-left",
                 timeout: 2500,
                 closeOnClick: true,
                 pauseOnFocusLoss: true,
                 pauseOnHover: true,
                 draggable: true,
                 draggablePercent: 0.6,
                 showCloseButtonOnHover: false,
                 hideProgressBar: true,
                 closeButton: "button",
                 rtl: false
              });
          });
    },
    expandMenu(index) {
        if (this.selectedMenuIndex == index) {
            this.selectedMenuIndex  = -1;
            document.title = 'foodmenu.fit';
            return;
        }

        this.selectedMenuIndex = index;

        this.menus[index].products = this.menus[index].products || [];

        this.menus[index].texts = this.menus[index].texts || {
            'Mic_dejun': '',
            'Gustare_1': '',
            'Pranz': '',
            'Gustare_2': '',
            'Cina': '',
        };

        this.updateMenuTotals();
    },
    currentDate() {
        return new Date().toISOString().substr(0, 10);
    },
    inputChange(searchTerm) {
        //this.isLoading = true;
        this.debouncedSearch(searchTerm);
    },
    inputChangeMenu(searchTerm) {
        //this.isLoading = true;
        this.debouncedSearchMenu(searchTerm);
    },
    paginateFoods(filteredFoods) {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return filteredFoods.slice(startIndex, endIndex);
    },
    searchProduct(searchTerm) {
        this.currentPage = 1;

        if (searchTerm == '') {
            this.filteredFoods = this.foods;
        } else {
            const searchTerms = searchTerm.trim().toLowerCase().split(' ');

            this.filteredFoods = this.foods.filter((food) => {
              const foodWords = food.name.toLowerCase().split(' ');
              return searchTerms.every((term) => foodWords.includes(term));
            });
        }

        this.isLoading = false;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    // Go to the next page
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    searchMenu(searchTerm) {
        if (searchTerm == '') {
            this.filteredMenus = this.menus;
            return;
        }

        const searchTerms = searchTerm.toLowerCase().split(' ');

        this.filteredMenus = this.menus.filter((menu) =>
            searchTerms.every((term) => menu.name.toLowerCase().includes(term))
        );

        this.isLoading = false;
    },
    debouncedSearch: debounce(function(searchTerm) {
        this.searchProduct(searchTerm);
    }, 550),
    debouncedSearchMenu: debounce(function(searchTerm) {
        this.searchMenu(searchTerm);
    }, 550),
    openAddProductModal(reset) {
        if (reset) {
            this.reset();
        }
        this.adaugaFoodModal = $('#adaugaFoodModal').modal({});
    },
    openAddMenuModal(reset) {
        if (reset) {
            this.resetMenu();
        }
        this.adaugaMenuModal = $('#adaugaMenuModal').modal({});
    },
    fetchData() {
        this.isLoading = true;

        onValue(this.foodsRef, (snapshot) => {
            const data = snapshot.val(); // Get the data from the snapshot

            if (data) {
                this.foods = data; // Populate 'this.foods' with the retrieved data
            } else {
                this.foods = []; // If there's no data, initialize the array as empty
            }

            this.filteredFoods = this.foods;

            this.isLoading = false;
        });

        onValue(this.menusRef, (snapshot) => {
            const data = snapshot.val(); // Get the data from the snapshot

            if (data) {
                this.menus = data; // Populate 'this.foods' with the retrieved data
            } else {
                this.menus = []; // If there's no data, initialize the array as empty
            }

            this.filteredMenus = this.menus;

            this.isLoading = false;
        });
    },
    updateDb(element) {
        let self = this;

        if (element == 'food') {
            set(this.foodsRef, this.foods)
                .then(() => {
                    self.reset();
                })
                .catch((error) => {
                    console.error('Error saving food data to Firebase:', error);
                });
        }

        if (element == 'menu') {
            set(this.menusRef, this.menus)
                .then(() => {
                    self.resetMenu();
                })
                .catch((error) => {
                    console.error('Error saving menu data to Firebase:', error);
                });
        }
    },
    filterMenuByPerioda(menu, perioada) {
        return menu.filter((item) => item.perioada === perioada);
    },
    findFoodById(foodId) {
        return this.foods.find((food) => food.id === foodId);
    },
    updateFoodProd() {
        this.updateMenuTotals();
        this.updateDb('menu');
    },
    updateMenuTotals() {
        let menu = this.menus[this.selectedMenuIndex];

        let totals = {};

        totals['select'] = {
            'cal': 0,
            'protein': 0,
            'sugars': 0,
            'fats': 0,
            'fiber': 0,
            'grm': 0
        }

        totals['grand'] = {
            'cal': 0,
            'protein': 0,
            'sugars': 0,
            'fats': 0,
            'fiber': 0,
            'grm': 0
        }

        for (let perioada of this.perioade) {
            totals[perioada] = {
                'cal': 0,
                'protein': 0,
                'sugars': 0,
                'fats': 0,
                'fiber': 0,
                'grm': 0
            }
        }

        //let menu = this.menus[this.selectedMenuIndex];
        if (!menu) return;

        document.title = menu.name;

        menu['totals'] = totals;

        if (!menu.products) return;

        for (let prod of menu.products) {
            //if (prod.perioada == '') continue;

            totals[prod.perioada]['cal'] += parseFloat(prod.cal);
            totals[prod.perioada]['protein'] += parseFloat(prod.protein);
            totals[prod.perioada]['sugars'] += parseFloat(prod.sugars);
            totals[prod.perioada]['fats'] += parseFloat(prod.fats);
            totals[prod.perioada]['fiber'] += parseFloat(prod.fiber);
            totals[prod.perioada]['grm'] += parseFloat(prod.grm || 0);

            totals['grand']['cal'] += parseFloat(prod.cal);
            totals['grand']['protein'] += parseFloat(prod.protein);
            totals['grand']['sugars'] += parseFloat(prod.sugars);
            totals['grand']['fats'] += parseFloat(prod.fats);
            totals['grand']['fiber'] += parseFloat(prod.fiber);
            totals['grand']['grm'] += parseFloat(prod.grm || 0);
        }

        menu['totals'] = totals;

        return totals;
    },
    inputChangeMenuFoodBlur(target) {
        if (target.value.trim() == '') target.value = 0;
        this.updateDb('menu');
    },
    inputChangeMenuText() {
        this.updateDb('menu');
    },
    inputChangeMenuFood(menuFoodProd, grm) {
        //if (grm == '') grm = 0;
        //menuFoodProd.grm = grm;

        let originalFood = this.findFoodById(menuFoodProd.id);

        menuFoodProd.cal = (originalFood.cal * grm/100).toFixed(2);
        menuFoodProd.protein = (originalFood.protein * grm/100).toFixed(2);
        menuFoodProd.sugars = (originalFood.sugars * grm/100).toFixed(2);
        menuFoodProd.fats = (originalFood.fats * grm/100).toFixed(2);
        menuFoodProd.fiber = (originalFood.fiber * grm/100).toFixed(2);

        this.updateMenuTotals();

        if (grm != '') {
            this.updateDb('menu');
        }
    },
    addMenu() {
        if (this.isEditModeMenu) {
            const editedIndex = this.menus.findIndex((menu) => menu.id === this.currentMenu.id);

            if (editedIndex !== -1) {
                this.currentMenu.id = CryptoJS.MD5(this.currentMenu.name).toString();

                this.menus[editedIndex] = { ...this.currentMenu };

                this.toast.info("Meniul a fost modificat", {
                   position: "top-left",
                   timeout: 2500,
                   closeOnClick: true,
                   pauseOnFocusLoss: true,
                   pauseOnHover: true,
                   draggable: true,
                   draggablePercent: 0.6,
                   showCloseButtonOnHover: false,
                   hideProgressBar: true,
                   closeButton: "button",
                   rtl: false
                });
            }
        } else {
          this.currentMenu.id = CryptoJS.MD5(this.currentMenu.name).toString();
          this.currentMenu.perioada = this.currentMenu.perioada || 'select';

          this.menus.push({ ...this.currentMenu });

          this.selectedMenuIndex =  this.menus.length - 1;

          this.currentMenu = {
            products: [],
            texts: {
                'Mic_dejun': '',
                'Gustare_1': '',
                'Pranz': '',
                'Gustare_2': '',
                'Cina': '',
            },
            name: "",
            id: ""
          };

          this.toast.success("Meniul a fost adaugat", {
             position: "top-left",
             timeout: 2500,
             closeOnClick: true,
             pauseOnFocusLoss: true,
             pauseOnHover: true,
             draggable: true,
             draggablePercent: 0.6,
             showCloseButtonOnHover: false,
             hideProgressBar: true,
             closeButton: "button",
             rtl: false
          });
        }


        this.updateMenuTotals();

        this.updateDb('menu');
    },
    reset() {
        this.currentFood = {
            id: "",
            category: "",
            name: "",
            cal: null,
            protein: null,
            sugars: null,
            fats: null,
            fiber: null,
        };

        this.isEditMode = false;
    },
    resetMenu() {
        this.currentMenu = {
          id: "",
          products: [],
          name: "",
          texts: {
              'Mic_dejun': '',
              'Gustare_1': '',
              'Pranz': '',
              'Gustare_2': '',
              'Cina': '',
          },
        },

        this.isEditModeMenu = false;
    },
    deleteMenuFood(prod_delete) {
        const isConfirmed = window.confirm("Sigur doresti sa stergi aceast produs (" + prod_delete.name + ") din meniu?");

        if (isConfirmed) {
            const indexToDelete = this.menus[this.selectedMenuIndex].products.findIndex(product => product.id === prod_delete.id);

            if (indexToDelete !== -1) {
                this.menus[this.selectedMenuIndex].products.splice(indexToDelete, 1);

                this.toast.error("Produsul " + prod_delete.name + " a fost sters din meniu", {
                   position: "top-left",
                   timeout: 2500,
                   closeOnClick: true,
                   pauseOnFocusLoss: true,
                   pauseOnHover: true,
                   draggable: true,
                   draggablePercent: 0.6,
                   showCloseButtonOnHover: false,
                   hideProgressBar: true,
                   closeButton: "button",
                   rtl: false
                });

                this.updateMenuTotals();

                this.updateDb('menu');
            } else {
                alert('Produsul nu a fost gasit');
            }
        }
    },
    editFood(food) {
        this.openAddProductModal();

        const selectedFood = food;

        this.currentFood = {
            id: selectedFood.id,
            category: selectedFood.category,
            name: selectedFood.name,
            cal: selectedFood.cal,
            protein: selectedFood.protein,
            sugars: selectedFood.sugars,
            fats: selectedFood.fats,
            fiber: selectedFood.fiber
        };

        this.isEditMode = true;
    },
    editMenu(index) {
        this.openAddMenuModal();

        const selectedMenu = this.menus[index];

        this.currentMenu = {
            id: selectedMenu.id,
            name: selectedMenu.name,
            products: selectedMenu.products,
            texts: selectedMenu.texts
        };

        this.isEditModeMenu = true;
    },
    deleteFood(tfood) {
        const isConfirmed = window.confirm("Sigur doresti sa stergi aceast produs?");

        if (isConfirmed) {
            const deletedIndex = this.foods.findIndex((food) => food.id === tfood.id);

            this.foods.splice(deletedIndex, 1);

            this.toast.error("Produsul a fost sters", {
               position: "top-left",
               timeout: 2500,
               closeOnClick: true,
               pauseOnFocusLoss: true,
               pauseOnHover: true,
               draggable: true,
               draggablePercent: 0.6,
               showCloseButtonOnHover: false,
               hideProgressBar: true,
               closeButton: "button",
               rtl: false
            });

            this.updateDb('food');
        }
    },
    forceFileDownload(fileName) {
      var downloadLink = document.createElement('a');
      downloadLink.href = 'https://foodmenu.fit/server/export/' + fileName;

      downloadLink.setAttribute('download', fileName);

      if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        downloadLink.dispatchEvent(event);
      } else {
        downloadLink.click();
      }
    },
    showHtml(html) {
        this.current_html = html;
        this.menuModal = $('#menuModal').modal({});
    },
    exportMenu(index, format) {
        let self = this;
        let selectedMenu = this.menus[index];

        axios.post('https://www.foodmenu.fit/server/index.php?format=' + format, selectedMenu)
            .then(function (response) {
              console.log('Response from PHP:', response.data);
              if (format == 'xls') {
                  self.forceFileDownload(response.data.filename);
              }

              if (format == 'html') {
                  self.showHtml(response.data.html);
              }
            })
            .catch(function (error) {
              console.error('Error:', error);
              alert('A aparut o eroare la export');
            });
    },
    doBackup() {
        axios.post('https://www.foodmenu.fit/server/backup.php', [this.menus, this.foods])
            .then(function (response) {
              console.log('Response from PHP:', response.data);
            })
            .catch(function (error) {
              console.error('Error:', error);
            });
    },
    deleteMenu(index) {
        const isConfirmed = window.confirm("Sigur doresti sa stergi aceast meniu?");

        if (isConfirmed) {
            this.menus.splice(index, 1);

            this.toast.error("Meniul a fost sters", {
               position: "top-left",
               timeout: 2500,
               closeOnClick: true,
               pauseOnFocusLoss: true,
               pauseOnHover: true,
               draggable: true,
               draggablePercent: 0.6,
               showCloseButtonOnHover: false,
               hideProgressBar: true,
               closeButton: "button",
               rtl: false
            });

            this.selectedMenuIndex = -1;

            this.updateMenuTotals();

            this.updateDb('menu');
        }
    },
    copyTable() {
        //navigator.clipboard.writeText(this.current_html);
        window.getSelection().removeAllRanges();
        let range = document.createRange();
        range.selectNode(document.getElementById('menuTable'));
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        alert('Meniul a fost copiat!');
    },
    addToMenu(food) {
        if (this.selectedMenuIndex == -1) {
            alert('Selectati un meniu din partea dreapta sau creati un meniu nou');
            return;
        }

        this.menus[this.selectedMenuIndex].products = this.menus[this.selectedMenuIndex].products || [];

        this.menus[this.selectedMenuIndex].products.push({
            grm: 100,
            perioada: 'select',
            ...food
        });

        this.toast.info("Produsul a fost adaugat la meniu", {
           position: "top-left",
           timeout: 2500,
           closeOnClick: true,
           pauseOnFocusLoss: true,
           pauseOnHover: true,
           draggable: true,
           draggablePercent: 0.6,
           showCloseButtonOnHover: false,
           hideProgressBar: true,
           closeButton: "button",
           rtl: false
       });

       this.updateMenuTotals();

       this.updateDb('menu');
    },
    addFood() {
        if (this.isEditMode) {
            const editedIndex = this.foods.findIndex((food) => food.id === this.currentFood.id);

            if (editedIndex !== -1) {
                this.currentFood.id = CryptoJS.MD5(this.currentFood.name).toString();

                this.foods[editedIndex] = { ...this.currentFood };

                this.toast.info("Produsul a fost modificat", {
                   position: "top-left",
                   timeout: 2500,
                   closeOnClick: true,
                   pauseOnFocusLoss: true,
                   pauseOnHover: true,
                   draggable: true,
                   draggablePercent: 0.6,
                   showCloseButtonOnHover: false,
                   hideProgressBar: true,
                   closeButton: "button",
                   rtl: false
                });
            }
        } else {
            this.currentFood.id = CryptoJS.MD5(this.currentFood.name).toString();

            this.foods.push({ ...this.currentFood });

            this.toast.success("Produsul a fost adaugat", {
               position: "top-left",
               timeout: 2500,
               closeOnClick: true,
               pauseOnFocusLoss: true,
               pauseOnHover: true,
               draggable: true,
               draggablePercent: 0.6,
               showCloseButtonOnHover: false,
               hideProgressBar: true,
               closeButton: "button",
               rtl: false
            });
        }

        this.updateDb('food');
    },
  },
};
</script>

<style>
    .modal-backdrop {
        opacity: 0.85!important;
    }

    body.modal-open .container-fluid {
        filter: blur(5px);
    }

    table.table-condensed  tr td {
        padding-top:0px;
        padding-bottom:0px;
    }

    table.table-condensed  tr td {
        font-size: 12px;
    }

    .bg-pale {
        background-color: rgba(255,255,0,0.4)!important;
    }
    .disabled-fake {
        opacity: 0.35;
    }

    .sticky {
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: white;
    }

    td, th {
        vertical-align: middle!important;
    }

    .selected .menuName {
        font-weight: bold;
        font-size: 22px;
        color: red;
    }

    .selected table tr td {
        padding-top: 2px;
        padding-bottom: 2px;
    }
</style>
