import { createApp } from 'vue'
import App from './App.vue'
import Toast from 'vue-toastification'

import 'vue-toastification/dist/index.css'
import 'font-awesome/css/font-awesome.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const app = createApp(App);

app.use(Toast);

app.mount('#app');
