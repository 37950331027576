<template>
  <div class="loader-overlay">
    <div class="loader">
      <!-- Add your loader HTML or animation here -->
      <div class="spinner"></div>
      <p>Loading...</p>
    </div>
  </div>
</template>

<style scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Make sure it's on top of other content */
}

.loader {
  text-align: center;
  color: white; /* Text color */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center text and spinner horizontally */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #007BFF;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 16px; /* Add some spacing between the spinner and text */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
